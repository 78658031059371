
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import CreateStaffAndAdminUser from "@/components/admin/CreateStaffAndAdminUser.vue";
import variables from "@/router/api";

export default defineComponent({
  name: "admin-add-new-ibn-staff",
  components: {
    CreateStaffAndAdminUser,
  },
  data() {
    return {
      route: variables.SUPERADMIN_COOPERATIVE_ROUTE,
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Add New", ["Users", "IBN Staffs"]);
    });
  },
  created() {
    //Set page title
    document.title = "Add New IBN Staff | " + process.env.VUE_APP_TITLE;
  },
});
